.plans-container {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  padding: 0 2rem;
  margin-top: -4rem;
  position: relative;
}
.plans-blur-1 {
  width: 32rem;
  height: 23rem;
  top: 6rem;
  left: 0rem;
}
.plans-blur-2 {
  width: 32rem;
  height: 23rem;
  top: 10rem;
  right: 0rem;
}
.programs-header {
  display: flex;
  justify-content: center;
  font-weight: bold;
  gap: 3rem;
  color: white;
  font-size: 3rem;
  text-transform: uppercase;
  font-style: italic;
}

.plans {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3rem;
}
.plan {
  display: flex;
  flex-direction: column;
  background-color: var(--caloryCard);
  color: white;
  gap: 2rem;
  padding: 1.5rem;
  width: 15rem;
}
.plan:nth-child(2) {
  background-color: rgb(200, 155, 70);
  transform: scale(1.1);
}
.plan > svg {
  fill: orange;
  width: 2rem;
  height: 2rem;
}
.plan > :nth-child(2) {
  font-size: 1rem;
  font-weight: bold;
}
.plan > :nth-child(3) {
  font-size: 3rem;
  font-weight: bold;
}
.plan > :nth-child(5) {
  font-size: 0.8rem;
}
.features {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.features {
  display: flex;
  gap: 1rem;
}
.features > div > img {
  width: 0.8rem;
  margin-right: 0.8rem;
}

@media screen and (max-width: 768px) {
  .programs-header {
    flex-direction: column;
    gap: 1rem;
    font-size: x-large;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
  .plans {
    flex-direction: column;
  }
  .plans > :nth-child(2) {
    transform: none;
  }
}
